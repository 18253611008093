<template>
  <v-container grid-list-xs>
    <h1>About</h1>
    <v-divider></v-divider>
    <br>
    <v-card class="elevation-12">
      <v-card-title>
        <h2>📹 VidToBlog: The Ultimate Content Multiplier for Creators!</h2>
      </v-card-title>
      <v-card-subtitle>
        <br />
        <h4>📝 Are you a content creator juggling between YouTube videos and SEO-friendly blog posts? Meet VidToBlog,
          your new secret weapon to effortlessly repurpose your video content using the power of AI!</h4>
      </v-card-subtitle></v-card-subtitle></v-card-subtitle>

      <v-card-text>
        <h3>👉 Why Choose VidToBlog?</h3>
        <v-list>
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="primary">mdi-recycle</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Content Recycling, Elevated</v-list-item-title>
              <v-list-item-subtitle>Transform your engaging YouTube videos into polished, SEO-ready blog articles in
                seconds. Don't just create; multiply!</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-icon color="green">mdi-clock-fast</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>On-the-Fly Summaries</v-list-item-title>
              <v-list-item-subtitle>Short on time? Get concise, sharable summaries of your videos to
                boost engagement and widen your reach.</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-icon color="orange">mdi-trending-up</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>SEO Supercharge</v-list-item-title>
              <v-list-item-subtitle>Elevate your SEO game by converting videos to text. It's like having your cake and
                eating it too!</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-icon color="blue">mdi-file-document-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>New! Transcript Generation</v-list-item-title>
              <v-list-item-subtitle>Unlock the full potential of video content! Get accurate transcripts of
                videos for research, study, or accessibility.</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <h3>✨ Key Features:</h3>

        <v-list>
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="primary">mdi-content-paste</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Copy-Paste Simplicity</v-list-item-title>
              <v-list-item-subtitle>Paste the YouTube URL, hit 'Create,' and voila!</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-icon color="green">mdi-send</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Ready-to-Post</v-list-item-title>
              <v-list-item-subtitle>Receive beautifully formatted blog posts, primed for
                publishing!</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-icon color="orange">mdi-file-document</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Speedy Summaries</v-list-item-title>
              <v-list-item-subtitle>Quick yet insightful, get the essence of your videos without breaking a
                sweat.</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-icon color="blue">mdi-check-circle-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Accurate Transcripts</v-list-item-title>
              <v-list-item-subtitle>Access word-for-word transcripts to deepen your understanding or to create additional
                content easily.</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <p>
        <h3>🎉 Unlock a New Realm of Content!</h3>
        <br />
        Why stick to one format when you can dominate both video and text? Use VidToBlog now and redefine content
        creation for the digital age.
        </p>
        <h3>📧 Contact Me</h3>
        <p>
          If you have any questions or suggestions, please feel free to reach out to me at <a
            href="mailto:info@tekanaid.com">info@tekanaid.com</a>.
        </p>
      </v-card-text>
    </v-card>
    <br />
    <YouTubeEmbed videoId="zuRGUcHW3WE" />

  </v-container>
</template>

<script>
import YouTubeEmbed from "@/components/YouTubeEmbed.vue";

export default {
  components: {
    YouTubeEmbed,
  },
};
</script>